<template>
  <div>
    <h2 style="text-transform: capitalize">{{ currentRouteName }}</h2>
    <el-form ref="config" :model="config">
      <el-row>
        <el-col :span="8">
          <el-form-item label="Tx type">
            <el-select
                v-model="form.txType"
                placeholder="please select your zone"
            >
              <el-option label="deposit" value="deposit"></el-option>
              <el-option label="recurring" value="recurring"></el-option>
              <el-option label="withdrawal" value="withdrawal"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <label class="el-form-item__label" style="float: none"
        >Json Body:
        </label>
        <div
            class="el-form-item__content"
            style="padding: 10px; border: 1px solid #cccccc"
        >
          <prism-editor
              v-model="form.body"
              :highlight="highlighter"
          ></prism-editor>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="initTransactionV2(form, result)"
        >Execute
        </el-button>
      </el-form-item>
      <div
          v-if="result.success"
          role="alert"
          class="el-alert el-alert--success is-light"
      >
        <!---->
        <div class="el-alert__content">
          <prism-editor
              v-model="result.success"
              :highlight="highlighter"
              :readonly="true"
          ></prism-editor>
        </div>
      </div>
      <div
          v-if="result.error"
          role="alert"
          class="el-alert el-alert--error is-light"
      >
        <!---->
        <div class="el-alert__content">
          <prism-editor
              v-model="result.error"
              :highlight="highlighter"
              :readonly="true"
          ></prism-editor>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Client from "../pg/client";
import ConfigStore from "../pg/config";

import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "InitTransactionV2",
  data() {
    const cfg = ConfigStore.read();
    console.log(cfg);
    return {
      config: cfg,
      form: {
        txType: "deposit",
        body: JSON.stringify(
            {
              merchantTransactionId: "",
              integrationType: "skrill",
              paymentMethodType: "skrill",
              customer: {
                id: "testID",
                country: "US",
                email: "boris_britva@gmail.com",
                phoneNumber: "13769874782",
                billingAddress: {
                  city: "Moscow",
                  countryCode: "US",
                  line1: "Mother",
                  line2: "Russia",
                  postalCode: "123456",
                  state: "US",
                },
              },
              customerBrowserSession: {
                ip: "127.0.0.1",
                fingerprint: "da9894826024f63c9ca06d0b89804112",
                acceptHeader: "*/*",
                colorDepth: 24,
                javaEnabled: false,
                language: "ru-RU",
                screenHeight: 1080,
                screenWidth: 1919,
                timeZoneOffset: -180,
                userAgent:
                    "Mozilla/5.0 (X11; Linux x86_64) AppleWebKit/537.36 (KHTML like Gecko) Chrome/94.0.4606.61 Safari/537.36",
              },
              operation: {
                amount: "1.00",
                merchantOperationId: "6716f370-ab6a-4f24-b120-81c267011184",
                currencyCode: "EUR",
                credentials: {
                  type: "SkrillCredentials",
                },
              },
              flow: "direct",
            },
            null,
            2
        ),
      },
      result: {
        success: "",
        error: "",
      },
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.json);
    },
    initTransactionV2(form, result) {
      let config = ConfigStore.read();
      result.error = result.success = "";
      const body = JSON.parse(form.body);
      let client = Client();
      client.config = config;
      client
          .initTransaction("v2", form.txType, body)
          .then((data) => {
            console.log(data);
            if (data.status !== 200) {
              result.error = JSON.stringify(data, null, 2);
            } else {
              result.success = JSON.stringify(data, null, 2);
            }
          })
          .catch((err) => {
            result.error = err.message;
          });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
