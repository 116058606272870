<template>
  <div>
    <h2 style="text-transform: capitalize">{{ currentRouteName }}</h2>
    <el-form ref="config" :model="config">
      <el-row>
        <el-col :span="8">
          <el-form-item label="Tx type">
            <el-select
                v-model="form.txType"
                placeholder="please select your zone"
            >
              <el-option label="deposit" value="deposit"></el-option>
              <el-option label="recurring" value="recurring"></el-option>
              <el-option label="withdrawal" value="withdrawal"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Country">
            <el-select filterable placeholder="" v-model="form.country">
              <el-option :value="''"/>
              <el-option
                  v-for="item in countries"
                  :key="item"
                  :label="item"
                  :value="item"
              ></el-option>
            </el-select>
            <!-- <el-input
              v-model="form.query"
              :placeholder="'userCountry=US&currency=USD'"
            ></el-input> -->
          </el-form-item>
        </el-col>
        <el-col :span="8">
          <el-form-item label="Currency">
            <el-select filterable placeholder="" v-model="form.currencyCode">
              <el-option :value="''"/>
              <el-option
                  v-for="item in currencyCodes"
                  :key="item"
                  :label="item"
                  :value="item"
              ></el-option>
            </el-select>
            <!-- <el-input
              v-model="form.query"
              :placeholder="'userCountry=US&currency=USD'"
            ></el-input> -->
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item :label="query"></el-form-item>
      <el-form-item>
        <el-button type="primary" @click="listMethods(form, result)"
        >Execute
        </el-button
        >
      </el-form-item>
      <div
          v-if="result.success"
          role="alert"
          class="el-alert el-alert--success is-light"
      >
        <!---->
        <div class="el-alert__content">
          <prism-editor
              v-model="result.success"
              :highlight="highlighter"
              :readonly="true"
          ></prism-editor>
        </div>
      </div>
      <div
          v-if="result.error"
          role="alert"
          class="el-alert el-alert--error is-light"
      >
        <!---->
        <div class="el-alert__content">
          <prism-editor
              v-model="result.error"
              :highlight="highlighter"
              :readonly="true"
          ></prism-editor>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Client from "../pg/client";
import ConfigStore from "../pg/config";

import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";
import {CurrencyCode} from "../pg/model/CurrencyCode";
import {CountryAlpha2} from "../pg/model/CountryAlpha2";

export default {
  name: "PaymentMethodsV2",
  data() {
    const cfg = ConfigStore.read();
    console.log(cfg);
    return {
      config: cfg,
      form: {
        txType: "deposit",
        currencyCode: "",
        country: "",
      },
      result: {
        success: "",
        error: "",
      },
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.json);
    },
    listMethods(form, result) {
      let config = ConfigStore.read();
      result.error = result.success = "";
      let client = Client();
      client.config = config;
      client
          .listMethods("v2", form.txType, this.query)
          .then((data) => {
            console.log(data);
            if (data.status != 200) {
              result.error = JSON.stringify(data, null, 2);
            } else {
              result.success = JSON.stringify(data, null, 2);
            }
          })
          .catch((err) => {
            result.error = err.message;
          });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
    currencyCodes() {
      const keys = Object.keys(CurrencyCode);
      keys.sort();
      return keys;
    },
    countries() {
      const keys = Object.keys(CountryAlpha2);
      keys.sort();
      return keys;
    },
    query() {
      const currency = this.$data.form.currencyCode
          ? this.$data.form.currencyCode
          : "";
      const country = this.$data.form.country ? this.$data.form.country : "";
      return `userCountry=${country}&currency=${currency}`;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
