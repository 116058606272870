import Vue from "vue";
import VueRouter from "vue-router";
import App from "./App.vue";
import "./plugins/element.js";
import PaymentMethodsV2 from "./components/PaymentMethodsV2";
import PaymentMethodsV1 from "./components/PaymentMethodsV1";
import TransactionV2 from "./components/TransactionV2";
import TransactionV1 from "./components/TransactionV1";
import SubmitTransaction from "./components/SubmitTransaction.vue";
import ListCustomerAccounts from "./components/ListCustomerAccounts.vue";
import CustomerAccount from "./components/CustomerAccount.vue";
import GetTransaction from "./components/GetTransaction.vue";
import GetOperation from "./components/GetOperation.vue";
import GetSession from "./components/GetSession.vue";
import UpdateOperation from "./components/UpdateOperation.vue";
import ProcessOperation from "./components/ProcessOperation.vue";
import CustomRequest from "./components/CustomRequest.vue";

import "element-ui/lib/theme-chalk/index.css";
import "vue-prism-editor/dist/prismeditor.min.css";
import "element-ui/lib/theme-chalk/reset.css";

import {PrismEditor} from "vue-prism-editor";

Vue.component("PrismEditor", PrismEditor);

Vue.config.productionTip = false;
Vue.use(VueRouter);

const router = new VueRouter({
    routes: [
        {
            path: "/payment-methods",
            name: "paymentMethods",
            component: PaymentMethodsV1,
        },
        {
            path: "/v2/payment-methods",
            name: "paymentMethodsV2",
            component: PaymentMethodsV2,
        },
        {
            path: "/v2/init-transaction",
            name: "initTransactionV2",
            component: TransactionV2,
        },
        {
            path: "/init-transaction",
            name: "initTransaction",
            component: TransactionV1,
        },
        {
            path: "/submit-transaction",
            name: "submitTransaction",
            component: SubmitTransaction,
        },
        {
            path: "/list-customer-accounts",
            name: "listCustomerAccounts",
            component: ListCustomerAccounts,
        },
        {
            path: "/customer-account",
            name: "customerAccount",
            component: CustomerAccount,
        },
        {
            path: "/transaction",
            name: "transaction",
            component: GetTransaction,
        },
        {
            path: "/operation",
            name: "operation",
            component: GetOperation,
        },
        {
            path: "/session",
            name: "session",
            component: GetSession,
        },
        {
            path: "/update-operation",
            name: "updateOperation",
            component: UpdateOperation,
        },
        {
            path: "/process-operation",
            name: "processOperation",
            component: ProcessOperation,
        },
        {
            path: "/custom-request",
            name: "customRequest",
            component: CustomRequest,
        }
    ],
});

new Vue({
    router,
    render: (h) => h(App),
}).$mount("#app");
