import {v4 as uuidv4} from "uuid";
import jws from "jws";
import argon2 from "argon2-browser";
import sha256 from "crypto-js/sha256";

async function sign(data, requestId, secret, tokenId, version) {
    let checksum = "";
    let rule;
    switch (version) {
        case "0":
            console.log("v0");
            rule = await argon2.hash({
                pass: data,
                salt: "salt-salt",
                type: argon2.ArgonType.Argon2id,
            });
            checksum = rule.encoded;
            break;

        case "1":
            console.log("v1");
            checksum = sha256(data).toString();
            break;
    }

    console.log(checksum);
    const signature = await jws.sign({
        header: {alg: "HS512"},
        payload: {
            requestId: requestId,
            checksum: checksum,
            version: Number(version),
            tokenId: tokenId,
        },
        secret: Buffer.from(secret, "hex"),
    });
    return signature;
}

class Client {
    config = {
        baseURL: "",
        merchantID: "",
        projectID: "",
        projectToken: "",
        projectTokenID: "",
        version: "",
    };

    submitForm(body) {
        return this._fetch(`v1/transactions/submit-form`, body, {
            method: "POST",
        });
    }

    initTransaction(version, txType, body) {
        return this._fetch(`${version}/transactions/${txType}/init`, body, {
            method: "POST",
        });
    }

    listMethods(version, txType, query = "") {
        return this._fetch(
            `${version}/transactions/${txType}/payment-methods?${query}`, undefined,
            {
                method: "GET",
            }
        );
    }

    listCustomerAccounts(customerId) {
        return this._fetch(
            `v1/customers/${customerId}/customer-accounts`, undefined,
            {
                method: "GET",
            }
        );
    }

    getCustomerAccount(customerId, accountId) {
        return this._fetch(
            `v1/customers/${customerId}/customer-accounts/${accountId}`, undefined,
            {
                method: "GET",
            }
        );
    }

    getTransaction(txId) {
        return this._fetch(
            `v1/transactions/${txId}`, undefined,
            {
                method: "GET",
            }
        );
    }

    getOperation(opId) {
        return this._fetch(
            `v1/operations/${opId}`, undefined,
            {
                method: "GET",
            }
        );
    }

    updateOperation(opId, body) {
        return this._fetch(
            `v1/operations/${opId}`, body,
            {
                method: "PUT",
            }
        );
    }

    processOperation(opId) {
        return this._fetch(
            `v1/operations/${opId}`, undefined,
            {
                method: "POST",
            }
        );
    }

    getSession(sId) {
        return this._fetch(
            `v1/sessions/${sId}`,
            undefined,
            {
                method: "GET",
            }
        );
    }

    customFetch(path, data, method) {
        return this._fetch(
            path, data,
            {
                method: method,
            }
        );
    }

    async _fetch(
        path = "",
        data = undefined,
        options = {
            method: "GET", // *GET, POST, PUT, DELETE, etc.
            mode: "cors", // no-cors, *cors, same-origin
            cache: "default", // *default, no-cache, reload, force-cache, only-if-cached
            credentials: "same-origin", // include, *same-origin, omit
            headers: {
                "Content-Type": "application/json",
            },
            redirect: "follow", // manual, *follow, error
        }
    ) {
        // Default options are marked with *
        let body = undefined;
        if (data) {
            body = JSON.stringify(data);
        }
        options = Object.assign({}, options, {
            body: body,
        });
        const requestId = uuidv4();
        const signature = await sign(
            body ? body : "",
            requestId,
            this.config.projectToken,
            this.config.projectTokenID,
            this.config.version
        );
        options.headers = Object.assign(
            {},
            {
                "x-merchant-id": this.config.merchantID,
                "x-project-id": this.config.projectID,
                "x-request-id": requestId,
                Authorization: `Bearer ${signature}`,
            },
            options.headers
        );
        const response = await fetch(`${this.config.baseURL}/${path}`, options);
        const json = await response.json();
        const status = response.status;
        return await {body: json, status}; // parses JSON response into native JavaScript objects
    }
}

export default function () {
    return new Client();
}
