<template>
  <div>
    <h2 style="text-transform: capitalize">{{ currentRouteName }}</h2>
    <el-form ref="config" :model="config">
      <el-row>
        <el-col :span="8">
          <el-form-item label="Tx type">
            <el-select
                v-model="form.txType"
                placeholder="please select your zone"
            >
              <el-option label="deposit" value="deposit"></el-option>
              <el-option label="recurring" value="recurring"></el-option>
              <el-option label="withdrawal" value="withdrawal"></el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
      <el-form-item>
        <label class="el-form-item__label" style="float: none"
        >Json Body:
        </label>
        <div
            class="el-form-item__content"
            style="padding: 10px; border: 1px solid #cccccc"
        >
          <prism-editor
              v-model="form.body"
              :highlight="highlighter"
          ></prism-editor>
        </div>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" @click="initTransaction(form, result)"
        >Execute
        </el-button>
      </el-form-item>
      <div
          v-if="result.success"
          role="alert"
          class="el-alert el-alert--success is-light"
      >
        <!---->
        <div class="el-alert__content">
          <prism-editor
              v-model="result.success"
              :highlight="highlighter"
              :readonly="true"
          ></prism-editor>
        </div>
      </div>
      <div
          v-if="result.error"
          role="alert"
          class="el-alert el-alert--error is-light"
      >
        <!---->
        <div class="el-alert__content">
          <prism-editor
              v-model="result.error"
              :highlight="highlighter"
              :readonly="true"
          ></prism-editor>
        </div>
      </div>
    </el-form>
  </div>
</template>

<script>
import Client from "../pg/client";
import ConfigStore from "../pg/config";

import {highlight, languages} from "prismjs/components/prism-core";
import "prismjs/components/prism-clike";
import "prismjs/components/prism-json";
import "prismjs/themes/prism-tomorrow.css";

export default {
  name: "InitTransaction",
  data() {
    const cfg = ConfigStore.read();
    console.log(cfg);
    return {
      config: cfg,
      form: {
        txType: "deposit",
        body: JSON.stringify(
            {
              customer: {
                id: "c41fed51-7b1d-4b8c-89f3-101e26e825f0",
                name: "name",
                email: "user@example.com",
                phoneNumber: "string",
                birthDate: "2020-12-08",
                country: "AS",
                billingAddress: {
                  line1: "string",
                  line2: "string",
                  city: "string",
                  state: "string",
                  countryCode: "AS",
                  phone: "string",
                  postalCode: "string",
                },
                metadata: {},
              },
              session: {
                ip: "171.23.22.11",
                fingerprint: "13ab8a51bab9595fee4f8db50c0309f5",
                device: {
                  type: "string",
                  browser: {
                    platform: "string",
                    acceptHeader: "string",
                    userAgent: "string",
                    javaEnabled: true,
                    language: "ru-RU",
                    colorDepth: 24,
                    screenHeight: 1080,
                    screenWidth: 1919,
                    windowHeight: 1080,
                    windowWidth: 1919,
                    timeZoneOffset: -180,
                    timeZoneName: "Europe/Minsk"
                  },
                },
              },
              flow: "form",
              paymentMethod: "card-acquirer",
              operation: {
                amount: "10.00",
                currencyCode: "EUR",
                failUrl: "https://google.com",
                credentials: {
                  card: {
                    number: "4012000000060085",
                    holder: "Boris Britva",
                    expiryMonth: 10,
                    expiryYear: 22,
                    cvv: "111",
                    tokenize: true,
                  },
                  payer: {
                    merchantCustomerId: "john@doe.com",
                    firstName: "John",
                    lastName: "Doe",
                    document: "string",
                    birthDate: "1987-03-22",
                    email: "john@doe.com",
                    phoneNumber: "1207123456",
                    phoneCountryCode: "1",
                  },
                  billingAddress: {
                    postalCode: "6633",
                    city: "London",
                    state: "OR",
                    country: "US",
                    address: "Payer st.",
                  },
                },
                successUrl: "https://google.com",
              },
            },
            null,
            2
        ),
      },
      result: {
        success: "",
        error: "",
      },
    };
  },
  methods: {
    highlighter(code) {
      return highlight(code, languages.json);
    },
    initTransaction(form, result) {
      let config = ConfigStore.read();
      result.error = result.success = "";
      const body = JSON.parse(form.body);
      let client = Client();
      client.config = config;
      client
          .initTransaction("v1", form.txType, body)
          .then((data) => {
            console.log(data);
            if (data.status !== 200) {
              result.error = JSON.stringify(data, null, 2);
            } else {
              result.success = JSON.stringify(data, null, 2);
            }
          })
          .catch((err) => {
            result.error = err.message;
          });
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style></style>
