<template>
  <div id="app">
    <el-container>
      <el-container>
        <el-aside width="18%">
          <ul class="el-menu--vertical el-menu">
            <router-link class="el-menu-item" :to="{ name: 'paymentMethods' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Payment Methods</b><br/>GET /v1/transactions/{typ}/payment-methods</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'initTransaction' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Init Transaction</b><br/>POST /v1/transactions/{typ}/init</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'transaction' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Get Transaction</b><br/>GET /v1/transactions/{uuid}</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'submitTransaction' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Submit Transaction</b><br/>POST /v1/transactions/submit-form</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'listCustomerAccounts' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>List Customer Accounts</b><br/>GET /v1/customers/{uuid}/customer-accounts</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'customerAccount' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Customer Account</b><br/>GET /v1/customers/{uuid}/customer-accounts/{uuid}</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'operation' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Get Operation</b><br/>GET /v1/operations/{uuid}</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'updateOperation' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Update Operation</b><br/>PUT /v1/operations/{uuid}</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'processOperation' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Process Operation</b><br/>POST /v1/operations/{uuid}</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'session' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Get Session</b><br/>GET /v1/sessions/{uuid}</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'paymentMethodsV2' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Payment Methods V2</b><br/>GET /v2/transactions/{typ}/payment-methods</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'initTransactionV2' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Init Transaction V2</b><br/>POST /v2/transactions/{typ}/init</li>
            </router-link>
            <router-link class="el-menu-item" :to="{ name: 'customRequest' }" custom v-slot="{ navigate , isActive, isExactActive}">
              <li @click="navigate" :class="[isActive && 'router-link-active', isExactActive && 'is-active']" @keypress.enter="navigate" role="link"><b>Custom</b></li>
            </router-link>
          </ul>
        </el-aside>
      <el-main>
        <el-form ref="config" :model="config" label-width="150px" size="mini">
          <el-row>
            <el-col :span="12">
              <el-form-item label="Environment">
                <el-radio-group v-model="config.baseURL">
                  <el-radio-button label="https://processing.api.finteqhub.com">Production</el-radio-button>
                  <el-radio-button label="https://processing-sandbox.api.finteqhub.com">Sandbox</el-radio-button>
                  <el-radio-button label="https://processing-sandbox-api.pgw.baby">NewSandbox</el-radio-button>
                  <el-radio-button label="https://processing.api.pgw.today">Staging</el-radio-button>
                  <el-radio-button label="https://processing.api.burp.digital">New Staging</el-radio-button>
                </el-radio-group>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="BaseURL">
                <el-input v-model="config.baseURL" placeholder=""></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row>
            <el-col :span="12">
              <el-form-item label="Merchant">
                <el-input v-model="config.merchantID" placeholder=""></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="Project ID">
                <el-input v-model="config.projectID" placeholder="00000000-0000-0000-0000-000000000000"></el-input>
              </el-form-item>
            </el-col>
          </el-row>
          <el-form-item label="Project Token">
            <el-input v-model="config.projectToken" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="Project Token ID">
            <el-input v-model="config.projectTokenID" placeholder=""></el-input>
          </el-form-item>
          <el-form-item label="Alg version">
            <el-input v-model="config.version" placeholder=""></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="success" @click="saveConfig(config)"
              >Save config</el-button
            >
            <el-button @click="clearConfig(config)"
              >Clear</el-button
            >
          </el-form-item>
        </el-form>
        <router-view></router-view>
      </el-main>
      </el-container>
    </el-container>
  </div>
</template>

<script>
import ConfigStore from "./pg/config";

let clearConfig = (config) => {
  config.baseURL = "https://processing.api.burp.digital/v1";
  config.merchantID = "";
  config.projectID = "";
  config.projectToken = "";
}

export default {
  name: "app",
  props: {},
  data() {
    let cfg = {}
    clearConfig(cfg);
    const cfgStored = ConfigStore.read()
    cfg = Object.assign({}, cfg, cfgStored)
    console.log(cfg);
    return {
      config: cfg,
    };
  },
  methods: {
    saveConfig (cfg) {
      ConfigStore.store(cfg);
    },
    clearConfig (cfg) {
      clearConfig(cfg);
      ConfigStore.store(cfg);
    }
  },
};
</script>

<style>
#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}
.prism-editor__textarea:focus {
  outline: none;
}
.prism-editor__container {
  line-height: 1.3;
}

#app .el-menu-item {
  line-height: 1;
  height: unset;
  padding: 10px 5px;
  white-space-treatment: preserve;
}

</style>
