/*
 * Processing API
 * Processing API
 *
 * OpenAPI spec version: 0.0.1
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 *
 * Swagger Codegen version: 3.0.26
 *
 * Do not edit the class manually.
 *
 */

/**
 * Enum class CurrencyCode.
 * @enum {String}
 * @readonly
 */
const CurrencyCode = {
  /**
   * value: "AFN"
   * @const
   */
  AFN: "AFN",

  /**
   * value: "EUR"
   * @const
   */
  EUR: "EUR",

  /**
   * value: "ALL"
   * @const
   */
  ALL: "ALL",

  /**
   * value: "DZD"
   * @const
   */
  DZD: "DZD",

  /**
   * value: "USD"
   * @const
   */
  USD: "USD",

  /**
   * value: "AOA"
   * @const
   */
  AOA: "AOA",

  /**
   * value: "XCD"
   * @const
   */
  XCD: "XCD",

  /**
   * value: "ARS"
   * @const
   */
  ARS: "ARS",

  /**
   * value: "AMD"
   * @const
   */
  AMD: "AMD",

  /**
   * value: "AWG"
   * @const
   */
  AWG: "AWG",

  /**
   * value: "AUD"
   * @const
   */
  AUD: "AUD",

  /**
   * value: "AZN"
   * @const
   */
  AZN: "AZN",

  /**
   * value: "BSD"
   * @const
   */
  BSD: "BSD",

  /**
   * value: "BHD"
   * @const
   */
  BHD: "BHD",

  /**
   * value: "BDT"
   * @const
   */
  BDT: "BDT",

  /**
   * value: "BBD"
   * @const
   */
  BBD: "BBD",

  /**
   * value: "BYN"
   * @const
   */
  BYN: "BYN",

  /**
   * value: "BZD"
   * @const
   */
  BZD: "BZD",

  /**
   * value: "XOF"
   * @const
   */
  XOF: "XOF",

  /**
   * value: "BMD"
   * @const
   */
  BMD: "BMD",

  /**
   * value: "INR"
   * @const
   */
  INR: "INR",

  /**
   * value: "BTN"
   * @const
   */
  BTN: "BTN",

  /**
   * value: "BOB"
   * @const
   */
  BOB: "BOB",

  /**
   * value: "BOV"
   * @const
   */
  BOV: "BOV",

  /**
   * value: "BAM"
   * @const
   */
  BAM: "BAM",

  /**
   * value: "BWP"
   * @const
   */
  BWP: "BWP",

  /**
   * value: "NOK"
   * @const
   */
  NOK: "NOK",

  /**
   * value: "BRL"
   * @const
   */
  BRL: "BRL",

  /**
   * value: "BND"
   * @const
   */
  BND: "BND",

  /**
   * value: "BGN"
   * @const
   */
  BGN: "BGN",

  /**
   * value: "BIF"
   * @const
   */
  BIF: "BIF",

  /**
   * value: "CVE"
   * @const
   */
  CVE: "CVE",

  /**
   * value: "KHR"
   * @const
   */
  KHR: "KHR",

  /**
   * value: "XAF"
   * @const
   */
  XAF: "XAF",

  /**
   * value: "CAD"
   * @const
   */
  CAD: "CAD",

  /**
   * value: "KYD"
   * @const
   */
  KYD: "KYD",

  /**
   * value: "CLP"
   * @const
   */
  CLP: "CLP",

  /**
   * value: "CLF"
   * @const
   */
  CLF: "CLF",

  /**
   * value: "CNY"
   * @const
   */
  CNY: "CNY",

  /**
   * value: "COP"
   * @const
   */
  COP: "COP",

  /**
   * value: "COU"
   * @const
   */
  COU: "COU",

  /**
   * value: "KMF"
   * @const
   */
  KMF: "KMF",

  /**
   * value: "CDF"
   * @const
   */
  CDF: "CDF",

  /**
   * value: "NZD"
   * @const
   */
  NZD: "NZD",

  /**
   * value: "CRC"
   * @const
   */
  CRC: "CRC",

  /**
   * value: "HRK"
   * @const
   */
  HRK: "HRK",

  /**
   * value: "CUP"
   * @const
   */
  CUP: "CUP",

  /**
   * value: "CUC"
   * @const
   */
  CUC: "CUC",

  /**
   * value: "ANG"
   * @const
   */
  ANG: "ANG",

  /**
   * value: "CZK"
   * @const
   */
  CZK: "CZK",

  /**
   * value: "DKK"
   * @const
   */
  DKK: "DKK",

  /**
   * value: "DJF"
   * @const
   */
  DJF: "DJF",

  /**
   * value: "DOP"
   * @const
   */
  DOP: "DOP",

  /**
   * value: "EGP"
   * @const
   */
  EGP: "EGP",

  /**
   * value: "SVC"
   * @const
   */
  SVC: "SVC",

  /**
   * value: "ERN"
   * @const
   */
  ERN: "ERN",

  /**
   * value: "SZL"
   * @const
   */
  SZL: "SZL",

  /**
   * value: "ETB"
   * @const
   */
  ETB: "ETB",

  /**
   * value: "FKP"
   * @const
   */
  FKP: "FKP",

  /**
   * value: "FJD"
   * @const
   */
  FJD: "FJD",

  /**
   * value: "XPF"
   * @const
   */
  XPF: "XPF",

  /**
   * value: "GMD"
   * @const
   */
  GMD: "GMD",

  /**
   * value: "GEL"
   * @const
   */
  GEL: "GEL",

  /**
   * value: "GHS"
   * @const
   */
  GHS: "GHS",

  /**
   * value: "GIP"
   * @const
   */
  GIP: "GIP",

  /**
   * value: "GTQ"
   * @const
   */
  GTQ: "GTQ",

  /**
   * value: "GBP"
   * @const
   */
  GBP: "GBP",

  /**
   * value: "GNF"
   * @const
   */
  GNF: "GNF",

  /**
   * value: "GYD"
   * @const
   */
  GYD: "GYD",

  /**
   * value: "HTG"
   * @const
   */
  HTG: "HTG",

  /**
   * value: "HNL"
   * @const
   */
  HNL: "HNL",

  /**
   * value: "HKD"
   * @const
   */
  HKD: "HKD",

  /**
   * value: "HUF"
   * @const
   */
  HUF: "HUF",

  /**
   * value: "ISK"
   * @const
   */
  ISK: "ISK",

  /**
   * value: "IDR"
   * @const
   */
  IDR: "IDR",

  /**
   * value: "XDR"
   * @const
   */
  XDR: "XDR",

  /**
   * value: "IRR"
   * @const
   */
  IRR: "IRR",

  /**
   * value: "IQD"
   * @const
   */
  IQD: "IQD",

  /**
   * value: "ILS"
   * @const
   */
  ILS: "ILS",

  /**
   * value: "JMD"
   * @const
   */
  JMD: "JMD",

  /**
   * value: "JPY"
   * @const
   */
  JPY: "JPY",

  /**
   * value: "JOD"
   * @const
   */
  JOD: "JOD",

  /**
   * value: "KZT"
   * @const
   */
  KZT: "KZT",

  /**
   * value: "KES"
   * @const
   */
  KES: "KES",

  /**
   * value: "KPW"
   * @const
   */
  KPW: "KPW",

  /**
   * value: "KRW"
   * @const
   */
  KRW: "KRW",

  /**
   * value: "KWD"
   * @const
   */
  KWD: "KWD",

  /**
   * value: "KGS"
   * @const
   */
  KGS: "KGS",

  /**
   * value: "LAK"
   * @const
   */
  LAK: "LAK",

  /**
   * value: "LBP"
   * @const
   */
  LBP: "LBP",

  /**
   * value: "LSL"
   * @const
   */
  LSL: "LSL",

  /**
   * value: "ZAR"
   * @const
   */
  ZAR: "ZAR",

  /**
   * value: "LRD"
   * @const
   */
  LRD: "LRD",

  /**
   * value: "LYD"
   * @const
   */
  LYD: "LYD",

  /**
   * value: "CHF"
   * @const
   */
  CHF: "CHF",

  /**
   * value: "MOP"
   * @const
   */
  MOP: "MOP",

  /**
   * value: "MKD"
   * @const
   */
  MKD: "MKD",

  /**
   * value: "MGA"
   * @const
   */
  MGA: "MGA",

  /**
   * value: "MWK"
   * @const
   */
  MWK: "MWK",

  /**
   * value: "MYR"
   * @const
   */
  MYR: "MYR",

  /**
   * value: "MVR"
   * @const
   */
  MVR: "MVR",

  /**
   * value: "MRU"
   * @const
   */
  MRU: "MRU",

  /**
   * value: "MUR"
   * @const
   */
  MUR: "MUR",

  /**
   * value: "XUA"
   * @const
   */
  XUA: "XUA",

  /**
   * value: "MXN"
   * @const
   */
  MXN: "MXN",

  /**
   * value: "MXV"
   * @const
   */
  MXV: "MXV",

  /**
   * value: "MDL"
   * @const
   */
  MDL: "MDL",

  /**
   * value: "MNT"
   * @const
   */
  MNT: "MNT",

  /**
   * value: "MAD"
   * @const
   */
  MAD: "MAD",

  /**
   * value: "MZN"
   * @const
   */
  MZN: "MZN",

  /**
   * value: "MMK"
   * @const
   */
  MMK: "MMK",

  /**
   * value: "NAD"
   * @const
   */
  NAD: "NAD",

  /**
   * value: "NPR"
   * @const
   */
  NPR: "NPR",

  /**
   * value: "NIO"
   * @const
   */
  NIO: "NIO",

  /**
   * value: "NGN"
   * @const
   */
  NGN: "NGN",

  /**
   * value: "OMR"
   * @const
   */
  OMR: "OMR",

  /**
   * value: "PKR"
   * @const
   */
  PKR: "PKR",

  /**
   * value: "PAB"
   * @const
   */
  PAB: "PAB",

  /**
   * value: "PGK"
   * @const
   */
  PGK: "PGK",

  /**
   * value: "PYG"
   * @const
   */
  PYG: "PYG",

  /**
   * value: "PEN"
   * @const
   */
  PEN: "PEN",

  /**
   * value: "PHP"
   * @const
   */
  PHP: "PHP",

  /**
   * value: "PLN"
   * @const
   */
  PLN: "PLN",

  /**
   * value: "QAR"
   * @const
   */
  QAR: "QAR",

  /**
   * value: "RON"
   * @const
   */
  RON: "RON",

  /**
   * value: "RUB"
   * @const
   */
  RUB: "RUB",

  /**
   * value: "RWF"
   * @const
   */
  RWF: "RWF",

  /**
   * value: "SHP"
   * @const
   */
  SHP: "SHP",

  /**
   * value: "WST"
   * @const
   */
  WST: "WST",

  /**
   * value: "STN"
   * @const
   */
  STN: "STN",

  /**
   * value: "SAR"
   * @const
   */
  SAR: "SAR",

  /**
   * value: "RSD"
   * @const
   */
  RSD: "RSD",

  /**
   * value: "SCR"
   * @const
   */
  SCR: "SCR",

  /**
   * value: "SLL"
   * @const
   */
  SLL: "SLL",

  /**
   * value: "SGD"
   * @const
   */
  SGD: "SGD",

  /**
   * value: "XSU"
   * @const
   */
  XSU: "XSU",

  /**
   * value: "SBD"
   * @const
   */
  SBD: "SBD",

  /**
   * value: "SOS"
   * @const
   */
  SOS: "SOS",

  /**
   * value: "SSP"
   * @const
   */
  SSP: "SSP",

  /**
   * value: "LKR"
   * @const
   */
  LKR: "LKR",

  /**
   * value: "SDG"
   * @const
   */
  SDG: "SDG",

  /**
   * value: "SRD"
   * @const
   */
  SRD: "SRD",

  /**
   * value: "SEK"
   * @const
   */
  SEK: "SEK",

  /**
   * value: "CHE"
   * @const
   */
  CHE: "CHE",

  /**
   * value: "CHW"
   * @const
   */
  CHW: "CHW",

  /**
   * value: "SYP"
   * @const
   */
  SYP: "SYP",

  /**
   * value: "TWD"
   * @const
   */
  TWD: "TWD",

  /**
   * value: "TJS"
   * @const
   */
  TJS: "TJS",

  /**
   * value: "TZS"
   * @const
   */
  TZS: "TZS",

  /**
   * value: "THB"
   * @const
   */
  THB: "THB",

  /**
   * value: "TOP"
   * @const
   */
  TOP: "TOP",

  /**
   * value: "TTD"
   * @const
   */
  TTD: "TTD",

  /**
   * value: "TND"
   * @const
   */
  TND: "TND",

  /**
   * value: "TRY"
   * @const
   */
  TRY: "TRY",

  /**
   * value: "TMT"
   * @const
   */
  TMT: "TMT",

  /**
   * value: "UGX"
   * @const
   */
  UGX: "UGX",

  /**
   * value: "UAH"
   * @const
   */
  UAH: "UAH",

  /**
   * value: "AED"
   * @const
   */
  AED: "AED",

  /**
   * value: "USN"
   * @const
   */
  USN: "USN",

  /**
   * value: "UYU"
   * @const
   */
  UYU: "UYU",

  /**
   * value: "UYI"
   * @const
   */
  UYI: "UYI",

  /**
   * value: "UYW"
   * @const
   */
  UYW: "UYW",

  /**
   * value: "UZS"
   * @const
   */
  UZS: "UZS",

  /**
   * value: "VUV"
   * @const
   */
  VUV: "VUV",

  /**
   * value: "VES"
   * @const
   */
  VES: "VES",

  /**
   * value: "VND"
   * @const
   */
  VND: "VND",

  /**
   * value: "YER"
   * @const
   */
  YER: "YER",

  /**
   * value: "ZMW"
   * @const
   */
  ZMW: "ZMW",

  /**
   * value: "ZWL"
   * @const
   */
  ZWL: "ZWL",
};

export {CurrencyCode};